<template>
    <h2>Klantgegevens</h2>
    <div class="row" v-if="isAdmin">
        <label for="name" class="col-sm-5 col-form-label">Klant</label>
        <div class="col-sm-7">
                
            <select v-model="connection.customer_id" class="form-select form-select-sm" id="customer_id">   
                <option v-for="customer in customers" :key="customer.id" :value="customer.id" >
                    {{customer.name}}
                </option>
            </select>
        </div>
    </div>

    <div class="mb-3 row">
        <label for="api_key" class="col-sm-5 col-form-label">Referentie</label>
        <div class="col-sm-7">
            <input type="text" class="form-control" id="name" v-model="connection.name">
        </div>
    </div>

    <div class="row" v-if="isAdmin">
        <label for="name" class="col-sm-5 col-form-label">Facturabel per</label>
        <div class="col-sm-7">
            <input type="date" v-model="connection.billing_start_date" class="form-date-input" id="billing_start_date" /> 
        </div>
    </div>

    <h2>Brongegevens</h2>

    <div class="row" v-if="isAdmin">
        <template v-for="source in sources" :key="source.id">
            <div class='col-sm-5'>
                <div class="form-check">
                    <input class="form-check-input" v-model="connection.source_connection.source_id" type="radio" name="source_id" :id="'source_id_' + source.id" :value="source.id"  @click="setEndpoint(source.id)">
                    <label class="form-check-label" :for="'source_id_' + source.id">
                        {{source.name}}
                    </label>
                </div>
            </div>
        </template>
    </div>

        <div class="mb-3 row" v-if="isAdmin">
            <label for="api_key" class="col-sm-5 col-form-label">API key</label>
            <div class="col-sm-7">
                <input type="text" class="form-control" id="api_key" v-model="source.api_key" @change="getInvoices">
            </div>
        </div>
    
    <!-- <template v-else>
        <div class="mb-3 row" v-if="isAdmin">
            <label for="api_key" class="col-sm-5 col-form-label">Api key</label>
            <div class="col-sm-7">
                 <input type="text" class="form-control" id="api_key" v-model="source.api_key" @change="getInvoices">
            </div>
        </div>
    </template> -->

    <div class="row" v-if="isAdmin">
        <label for="name" class="col-sm-5 col-form-label">Synchroniseren </label>
        <div class="col-sm-7">
            <select v-model="source.last_imported_invoice_identifier" class="form-select form-select-sm" id="country" @change="setExportStartDate($event)">
                <option disabled>Selecteer...</option>
                <option value="0">Alle facturen</option>
                <option v-for="Invoice in source_invoices" :key="Invoice.id" :value="Invoice.id">
                   na {{Invoice.identifier}} - [{{formatDate(Invoice.date_sent, 'isoDate')}}] &nbsp;&nbsp;
                </option>
                
            </select>
        </div>
    </div>
    <div class="row" v-if="source.export_start_date">
        <label for="name" class="col-sm-5 col-form-label">Synchroniseren na (datum)</label>
        <div class="col-sm-7">
            {{source.export_start_date}}
        </div>
    </div>
    <template v-if="connection.destination_connection.destination_id == 4 && connection.source_connection.source_id == 2">
        <div class="mb-3 row">
            <label for="api_key" class="col-sm-5 col-form-label">Administratie code</label>
            <div class="col-sm-2">
                <input type="text" class="form-control" id="administration" v-model="connection.destination_connection.administration">
            </div>
        </div>
    </template>
    
    
    <h2>Doelgegevens</h2>
    
    <div class="row" v-if="isAdmin">
        <template v-for="destination in destinations" :key="destination.id">
            <div class='col-sm-4'>
                <div class="form-check">
                    <input class="form-check-input" v-model="connection.destination_connection.destination_id" type="radio" name="destination_id" :id="'destination_id_' + destination.id" :value="destination.id">
                    <label class="form-check-label" :for="'destination_id_' + destination.id">
                        {{destination.name}}
                    </label>
                </div>
            </div>
        </template>
    </div>
    <P></P>
    <div class="row" v-if="isAdmin">
        <label for="name" class="col-sm-5 col-form-label">Automatisch exporteren</label>
        <div class="col-sm-7">
        <input type="checkbox" v-model="connection.automatic_transfers" :true-value="1"
            :false-value="0" class="form-check-input" id="automatic_transfers" />
        </div>
        <label for="name" class="col-sm-5 col-form-label">Exporteren stoppen na foutieve invoice</label>
        <div class="col-sm-7">
        <input type="checkbox" v-model="connection.stop_auto_exports_on_fail" :true-value="1"
            :false-value="0" class="form-check-input" id="stop_auto_exports_on_fail" />
        </div>
    </div>
    
    <template v-if="connection.id">
        <p></p>
        <template v-if="(connection.destination_connection.destination_id ==10 && isAdmin)">
            <div class="row">
                        <label for="name" class="col-sm-5 col-form-label">Land</label>
                        <div class="col-sm-7">
                            <select v-model="connection.destination_connection.country" class="form-select form-select-sm" id="country">   
                                <option v-for="country in countries" :key="country.id" :value="country.id" >
                                    {{country.name}}
                                </option>
                            </select>
                        </div>
                    </div>
        </template>
        <template v-if="destinationUsesOauth">
            <div class="row" v-if="connection.destination_connection.parent_id">
                <label for="name" class="col-sm-5 col-form-label">OAuth</label>
                <div class="col-sm-7">Connectie gaat via {{connection.destination_connection.parent ? connection.destination_connection.parent.connection.name : 'een andere koppeling'}}</div>
            </div>
            <template  v-else>
                <template  v-if="connection.destination_connection.destination_id == 4">
                    <template v-if="isAdmin">
                    <div class="row">
                        <label for="export_type" class="col-sm-5 col-form-label">Exporteren als</label>
                        <div class="col-sm-7">
                            <select v-model="connection.destination_connection.export_target" class="form-select form-select-sm" id="export_target">   
                                <option v-for="exportTargetOption in exportExactTargetOptions" :key="exportTargetOption.id"  :selected="exportTargetOption.id == 0" :value="exportTargetOption.id">
                                    {{exportTargetOption.name}}
                                </option>
                            </select>
                        </div>
                    </div>  
                    </template> 
                    <div class="row">
                        <label for="name" class="col-sm-5 col-form-label">Land</label>
                        <div class="col-sm-7">
                            <select v-model="connection.destination_connection.country" class="form-select form-select-sm" id="country">   
                                <option v-for="country in countries" :key="country.id" :value="country.id" >
                                    {{country.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    
                   
                  
                </template>
                <div class="row">
                    <label for="name" class="col-sm-5 col-form-label">OAuth</label>
                    <div class="col-sm-7">
                        <button class='btn btn-sm' :class="{'btn-primary' : !connection.destination_connection.oauth_accepted, 'btn-secondary' : connection.destination_connection.oauth_accepted}" @click.prevent="oauth">Aanmelden</button> 
                        <template v-if="connection.destination_connection.oauth_accepted"> 
                            <template v-if="connection.destination_connection.username">
                                Huidige gebruiker: {{connection.destination_connection.username}} 
                            </template>
                            <template v-else>
                                Koppeling actief
                            </template>
                        </template>
                    </div>
                </div>
            </template>
            
            <template  v-if="connection.destination_connection.destination_id == 6">
                <div class="row">
                    <label for="administration" class="col-sm-5 col-form-label">Administratie</label>
                    <div class="col-sm-7">
                        <input v-model="connection.destination_connection.administration" class="form-control form-control-sm" id="administration" />
                    </div>
                </div>
            </template>

            <template  v-if="isAdmin && connection.destination_connection.destination_id == 7">
                <div class="row">
                    <label for="administration" class="col-sm-5 col-form-label">Administratie</label>
                    <div class="col-sm-7">
                        <input v-model="connection.destination_connection.administration" class="form-control form-control-sm" id="administration" />
                    </div>
                </div>
                
                <div class="row">
                    <label for="next_debtor_number" class="col-sm-5 col-form-label">Eerst volgend debiteur nr.</label>
                        <div class="col-sm-7">
                        <input v-model="connection.destination_connection.next_debtor_number" class="form-control form-control-sm" id="next_debtor_number" />
                        </div>
                </div>
                </template>


            <template  v-if="connection.destination_connection.destination_id == 12">
                <div class="row">
                    <label for="administration" class="col-sm-5 col-form-label">Administratie</label>
                    <div class="col-sm-7">
                      <input v-model="connection.destination_connection.administration" class="form-control form-control-sm" id="administration" />
                    </div>
                </div>
                <div class="row">
                    <label for="debtor_account" class="col-sm-5 col-form-label">Debiteuren rekening</label>
                    <div class="col-sm-7">
                      <input v-model="connection.destination_connection.debtor_account" class="form-control form-control-sm" id="debtor_account" />
                    </div>
                </div>
                <div class="row">
                    <label for="costplace" class="col-sm-5 col-form-label">Kostenplaats</label>
                    <div class="col-sm-7">
                      <input v-model="connection.destination_connection.costplace" class="form-control form-control-sm" id="costplace" />
                    </div>
                </div>
            </template>
        </template>
        <template v-else>
            <template  v-if="connection.destination_connection.destination_id == 1">
                 <div class="row">
                    <template v-if="isAdmin">
                        <label for="export_type" class="col-sm-5 col-form-label">Exporteren als </label>
                        <div class="col-sm-7">
                            <select v-model="connection.destination_connection.export_target" class="form-select form-select-sm" id="export_target">   
                                <option v-for="exportTargetOption in exportAfasTargetOptions" :key="exportTargetOption.id" :value="exportTargetOption.id" >
                                    {{exportTargetOption.name}}
                                </option>
                            </select>
                        </div>
                    </template>
                    <label for="environment" class="col-sm-5 col-form-label">Omgevingscode</label>
                    <div class="col-sm-7">
                      <input v-model="connection.destination_connection.environment" class="form-control form-control-sm" id="environment" />
                    </div>

                    <label for="access_token" class="col-sm-5 col-form-label">Token</label>
                    <div class="col-sm-7">
                      <input v-model="connection.destination_connection.access_token" class="form-control form-control-sm" id="access_token" />
                    </div>
                    <label for="administration" class="col-sm-5 col-form-label">Administratie</label>
                    <div class="col-sm-7">
                    <input v-model="connection.destination_connection.administration" class="form-control form-control-sm" id="administration" />
                    </div>
                    <template v-if="connection.destination_connection.export_target == 0">    
                        <label for="next_debtor_number" class="col-sm-5 col-form-label">Eerst volgend debiteur nr.</label>
                        <div class="col-sm-7">
                        <input v-model="connection.destination_connection.next_debtor_number" class="form-control form-control-sm" id="next_debtor_number" />
                        </div>
                        <label for="debtor_account" class="col-sm-5 col-form-label">Debiteuren rekening</label>
                        <div class="col-sm-7">
                        <input v-model="connection.destination_connection.debtor_account" class="form-control form-control-sm" id="debtor_account" />
                        </div>
                        <label for="crosspost_account" class="col-sm-5 col-form-label">Grootboekrekening kruispost</label>
                        <div class="col-sm-7">
                        <input v-model="connection.destination_connection.crosspost_account" class="form-control form-control-sm" id="crosspost_account" />
                        </div>
                    </template>
                 </div>    
            </template>
            <template v-else-if="connection.destination_connection.destination_id == 10">
                <div class="row">
                <label for="access_token" class="col-sm-5 col-form-label">Accesskey</label>
                    <div class="col-sm-7">
                      <input v-model="connection.destination_connection.access_token" class="form-control form-control-sm" id="access_token" />
                    </div>
                </div>    
            </template>
            <template v-else-if="connection.destination_connection.destination_id == 15">
                <div class="row">
                <label for="access_token" class="col-sm-5 col-form-label">Accesstoken</label>
                    <div class="col-sm-7">
                      <input v-model="connection.destination_connection.access_token" class="form-control form-control-sm" id="access_token" />
                    </div>
                </div>    
            </template>
            <template v-else-if="connection.destination_connection.destination_id == 3">
                <div class="row">
                    <label for="whitelist_ip" class="col-sm-5 col-form-label">Server IP adres</label>
                    <div class="col-sm-7">
                      <input v-model="connection.destination_connection.whitelist_ip" class="form-control form-control-sm" id="whitelist_ip" />
                    </div>
                </div>
                <div class="row">
                    <label for="debtor_export_file" class="col-sm-5 col-form-label">Upload export debiteuren</label>
                    <div class="col-sm-7">
                        <input v-if="!connection.destination_connection.debtor_export_file" type="file" class="form-control form-control-sm" @change="processFile"/>
                        <span v-else>
                            Debiteuren gesynchroniseerd
                        </span>
                    </div>
                </div>
                <div class="row">
                    <label for="next_debtor_number" class="col-sm-5 col-form-label">Eerst volgend debiteur nr.</label>
                    <div class="col-sm-7">
                      <input v-model="connection.destination_connection.next_debtor_number" class="form-control form-control-sm" id="next_debtor_number" />
                    </div>
                </div>
                <div class="row">
                    <label for="division" class="col-sm-5 col-form-label">Divisie code</label>
                    <div class="col-sm-7">
                        <input class="form-control form-control-sm" v-model="connection.destination_connection.division" id="division"/>
                    </div>
                </div>
            </template>
            <template v-else-if="connection.destination_connection.destination_id == 16">
                <div class="row">
                    <label for="division" class="col-sm-5 col-form-label">Dossier</label>
                    <div class="col-sm-2">
                        <input class="form-control form-control-sm" v-model="connection.destination_connection.division" id="division"/>
                    </div>
                </div>
                <div class="row">
                    <label for="division" class="col-sm-5 col-form-label">Netwerk adres</label>
                    <div class="col-sm-7">
                        <input class="form-control form-control-sm" v-model="connection.destination_connection.cluster" id="cluster"/>
                    </div>
                </div>
                <div class="row">
                <label for="environment" class="col-sm-5 col-form-label">Rootpath</label>
                    <div class="col-sm-7">
                      <input v-model="connection.destination_connection.environment" class="form-control form-control-sm" id="environment" />
                    </div>
                </div> 
                <div class="row">
                <label for="username" class="col-sm-5 col-form-label">Gebruikersnaam</label>
                <div class="col-sm-7">
                    <input v-model="connection.destination_connection.username" class="form-control form-control-sm" id="username" />
                </div>
            </div>

            <div class="row">
                <label for="password" class="col-sm-5 col-form-label">Wachtwoord</label>
                <div class="col-sm-7">
                    <input v-model="connection.destination_connection.password" class="form-control form-control-sm" id="password" type="password"/>
                </div>
            </div>   
            </template>    
            <template v-else>  
            <div class="row">
                <label for="username" class="col-sm-5 col-form-label">Gebruikersnaam</label>
                <div class="col-sm-7">
                    <input v-model="connection.destination_connection.username" class="form-control form-control-sm" id="username" />
                </div>
            </div>

            <div class="row">
                <label for="password" class="col-sm-5 col-form-label">Wachtwoord</label>
                <div class="col-sm-7">
                    <input v-model="connection.destination_connection.password" class="form-control form-control-sm" id="password" type="password"/>
                </div>
            </div>
            </template>
        </template>
        <template  v-if="connection.destination_connection.destination_id != 10  && connection.destination_connection.destination_id != 9  && connection.destination_connection.destination_id != 5 && (connection.destination_connection.destination_id != 1 || connection.destination_connection.export_target == 0 ) && connection.destination_connection.destination_id != 15">
            <div class="row">
                <label for="journal" class="col-sm-5 col-form-label">Dagboek</label>
                <div class="col-sm-2">
                    <input v-model="connection.destination_connection.journal" class="form-control form-control-sm" id="journal"
                        required />
                </div>
            </div>
        </template>
        <template v-if="(connection.destination_connection.destination_id != 1 || connection.destination_connection.export_target == 0)">
            <div class="row">
                <label for="default_glaccount" class="col-sm-5 col-form-label">Standaard grootboekrekening</label>
                <div class="col-sm-2">
                    <input v-model="connection.destination_connection.default_glaccount" class="form-control form-control-sm" id="default_glaccount"
                        required />
                </div>
            </div>
        </template>
        <template  v-if="connection.destination_connection.destination_id == 4 && connection.source_connection.source_id == 2">
            <div class="row">
                <label for="counter_account" class="col-sm-5 col-form-label">Autmatische incasso code</label>
                <div class="col-sm-2">
                    <input v-model="connection.destination_connection.direct_debit_code" class="form-control form-control-sm" id="direct_debit_code"
                        required />
                </div>
            </div> 
        </template>
       
        <template  v-if="connection.destination_connection.destination_id == 11">
            <div class="row">
                <label for="counter_account" class="col-sm-5 col-form-label">Tegenrekening</label>
                <div class="col-sm-7">
                    <input v-model="connection.destination_connection.counter_account" class="form-control form-control-sm" id="counter_account"
                        required />
                </div>
            </div>
        </template>  
        <p></p>
        <template v-if="connection.destination_connection.destination_id == 4">
        <h2 >Optioneel </h2>
            <div class="row">
                <label for="use_identifier_as_ref" class="col-sm-5 col-form-label">Gebruik factuur nummer als referentie</label>
                <div class="col-sm-7">
                <input type="checkbox" v-model="connection.destination_connection.use_identifier_as_ref" :true-value="1"
                    :false-value="0" class="form-check-input" id="use_identifier_as_ref" />
                </div>
            </div>
            <div class="row">
                <label for="deferred_revenue" class="col-sm-5 col-form-label">Uitgestelde omzet</label>
                <div class="col-sm-7">
                <input type="checkbox" v-model="connection.destination_connection.deferred_revenue" :true-value="1"
                    :false-value="0" class="form-check-input" id="deferred_revenue" />
                </div>
            </div>
            <div class="row">
                    <label for="payment_condition" class="col-sm-5 col-form-label">Code betaalconditie</label>
                    <div class="col-sm-2">
                        <input class="form-control form-control-sm" v-model="connection.destination_connection.payment_condition" id="payment_condition"/>
                    </div>
                </div>
                
            <div class="row" v-if="isAdmin">    
            <label for="crosspost_account" class="col-sm-5 col-form-label">Grootboeknummer kruispost</label>
                <div class="col-sm-2">
                <input v-model="connection.destination_connection.crosspost_account" class="form-control form-control-sm" id="crosspost_account" />
                </div>
            </div>           
        </template>
        <template  v-if="(connection.destination_connection.destination_id == 4  || connection.destination_connection.destination_id==1 ) && connection.source_connection.source_id == 1">
            <div class="row">
                <label for="counter_account" class="col-sm-5 col-form-label">Gebruik kostenplaatsen vanuit bron</label>
                <div class="col-sm-2">
                    <input type="checkbox" v-model="connection.destination_connection.use_costplace" :true-value="1"
                    :false-value="0" class="form-check-input" id="use_costplace" />
                </div>
            </div>
            <template  v-if="(connection.destination_connection.destination_id == 1 && connection.source_connection.source_id == 1) && ( connection.destination_connection.use_costplace ==0 || connection.destination_connection.use_costplace == null )">
                <div class="row">
                <label for="division" class="col-sm-5 col-form-label">Kostenplaats</label>
               <div class="col-sm-2">
                    <input class="form-control form-control-sm" v-model="connection.destination_connection.costplace" id="costplace"/>
                </div>
            </div>    
            </template>    
             
        </template>
        <template v-if="connection.destination_connection.destination_id == 4 || connection.destination_connection.destination_id == 11 || (connection.destination_connection.destination_id ==10 && isAdmin)">
            <div class="row">
                <label for="name" class="col-sm-5 col-form-label">Verstuur bedragen <strong>inclusief</strong> BTW</label>
                <div class="col-sm-7">
                <input type="checkbox" v-model="connection.destination_connection.use_price_inc_vat" :true-value="1"
                    :false-value="0" class="form-check-input" id="use_price_inc_vat" />
                </div>
            </div>
        </template>
       
        <template v-if="destinationUsesPayments">
        <div class="row">
                <label for="name" class="col-sm-5 col-form-label">Synchroniseer betaalstatus</label>
                <div class="col-sm-7">
                <input type="checkbox" v-model="connection.destination_connection.get_payments" :true-value="1"
                    :false-value="0" class="form-check-input" id="get_payments" />
                </div>
            </div>
        </template>    
        <template v-if="connection.destination_connection.destination_id == 3">
            <div class="row">
                <label for="urlhash" class="col-sm-5 col-form-label">URL export debiteuren</label>
                <div class="col-sm-7">
                    <input class="form-control-plaintext form-control-sm" id="urlhash" readonly :value="'https://globe.boekhoudapi.nl/exportDebtors/' + connection.destination_connection.urlhash" />
                </div>
            </div>
            <div class="row">
                <label for="urlhash" class="col-sm-5 col-form-label">URL export facturen</label>
                <div class="col-sm-7">
                    <input class="form-control-plaintext form-control-sm" id="urlhash" readonly :value="'https://globe.boekhoudapi.nl/exportGlentries/' + connection.destination_connection.urlhash" />
                </div>
            </div>
        </template>

        <p></p>
       <!-- <template v-if="connection.destination_connection.destination_id != 2">-->
         
        <template v-if="connection.destination_connection.export_target == 0 || (connection.destination_connection.export_target == 1 && connection.destination_connection.destination_id==4) ">
            <template v-if="connection.destination_connection.destination_id != 9  ">
                <template v-if="(connection.destination_connection.destination_id == 5 ) && isAdmin">   
                    <h2>BTW codes 
                        <template v-if="connection.destination_connection.use_price_inc_vat">(Inclusief BTW)</template>
                        <template v-else>(Exclusief BTW)</template>
                    </h2>
                </template>
                <template v-else-if="connection.destination_connection.destination_id == 3">
                    <h2>BTW codes (Inclusief BTW)</h2>
                </template>
                <template v-else>
                    <h2>BTW codes</h2>
                </template>
            </template>
            <template v-else> 
                <h2>Standaard product (Exclusief BTW)</h2>
            </template>
            <template v-if="connection.destination_connection.destination_id == 9 && isAdmin">
                    <div class="row">
                        <label for="name" class="col-sm-5 col-form-label">BTW berekening over regeltotaal</label>
                        <div class="col-sm-7">
                        <input type="checkbox" v-model="connection.destination_connection.calc_vat" :true-value="1"
                            :false-value="0" class="form-check-input" id="calc_vat" />
                        </div>
                    </div>
            </template>
            <template v-if="connection.destination_connection.destination_id != 5 || isAdmin">  
            <div class="row" v-for='vatcode of connection.destination_connection.vat_codes' :key=vatcode.id>
                <template v-if="connection.destination_connection.destination_id == 16">
                    <template v-if="filterVatCodes('BE', vatcode.description)">
                        <label for="name" class="col-sm-5 col-form-label">{{vatcode.description}}</label>
                        <div class="col-sm-2">
                            <input class="form-control form-control-sm" v-model="vatcode.code" />
                        </div>
                    </template>
                </template>
                <template v-else>
                    <template v-if="filterVatCodes(connection.destination_connection.country, vatcode.description)">
                        <label for="name" class="col-sm-5 col-form-label">{{vatcode.description}}</label>
                        <div class="col-sm-2">
                            <input class="form-control form-control-sm" v-model="vatcode.code" />
                        </div>
                    </template>
                </template>
            </div>
            </template>    
        </template> 
        <template v-if="connection.destination_connection.destination_id == 4 || connection.destination_connection.destination_id == 2 || connection.destination_connection.destination_id == 1 || connection.destination_connection.destination_id == 9 || connection.destination_connection.destination_id == 5 || connection.destination_connection.destination_id == 11  || connection.destination_connection.destination_id == 5 || connection.destination_connection.destination_id == 16">
           
            <h2>Extra opties</h2>
            <template v-if="connection.destination_connection.destination_id == 4 || connection.destination_connection.destination_id == 2 || connection.destination_connection.destination_id == 1" >
            <div class="row">
                <label for="description_target" class="col-sm-5 col-form-label">Factuuromschrijving synchroniseren</label>
                <div class="col-sm-7">
                <template v-if="connection.destination_connection.destination_id == 4">     
                    <select v-model="connection.destination_connection.description_target" class="form-select form-select-sm" id="description_target">   
                        <option v-for="descriptionOption in descriptionOptions" :key="descriptionOption.id" :value="descriptionOption.id" >
                            {{descriptionOption.name}}
                        </option>
                        
                    </select>
                </template>
                <template v-if="connection.destination_connection.destination_id == 2">     
                    <select v-model="connection.destination_connection.description_target" class="form-select form-select-sm" id="description_target">   
                        <option v-for="descriptionOption in descriptionOptionsSnelstart" :key="descriptionOption.id" :value="descriptionOption.id" >
                            {{descriptionOption.name}}
                        </option>
                        
                    </select>
                </template>
                <template v-if="connection.destination_connection.destination_id == 1">     
                    <select v-model="connection.destination_connection.description_target" class="form-select form-select-sm" id="description_target">   
                        <option v-for="descriptionOption in descriptionOptionsAFAS" :key="descriptionOption.id" :value="descriptionOption.id" >
                            {{descriptionOption.name}}
                        </option>
                        
                    </select>
                </template>


                </div>
                
            </div>
            

             <div class="row" v-if="isAdmin && connection.destination_connection.destination_id != 2 && connection.destination_connection.destination_id != 1">
                <label for="division" class="col-sm-5 col-form-label">Divisie code</label>
               <div class="col-sm-2">
                    <input class="form-control form-control-sm" v-model="connection.destination_connection.division" id="division"/>
                </div>
            </div>
            <div class="row" v-if="otherConnections.length > 1">
                <label for="parent_id" class="col-sm-5 col-form-label">Gebruik toegang van</label>
                <div class="col-sm-7">
                    <select v-model="connection.destination_connection.parent_id" class="form-select form-select-sm" id="parent_id">
                            <option :value="null">
                                Huidige koppeling
                            </option>
                        <template v-for="otherConnection in otherConnections" :key="otherConnection.id">
                            <option :value="otherConnection.destination_connection.id" v-if="otherConnection.destination_connection.id != connection.destination_connection.id">
                                {{otherConnection.name}}
                            </option>
                        </template>
                    </select>
                </div>
            </div>
            </template>
        
            <div class="row" v-if="connection.destination_connection.destination_id == 2">
                <label for="division" class="col-sm-5 col-form-label">Kostenplaats</label>
               <div class="col-sm-7">
                    <input class="form-control form-control-sm" v-model="connection.destination_connection.costplace" id="costplace"/>
                </div>
            </div> 
            <template v-if="connection.source_connection.source_id == 1">
            <template v-if="isAdmin">
                <div class="row">
                    <label for="synchronise_pdf" class="col-sm-5 col-form-label">Synchroniseer PDF facturen</label>
                    <div class="col-sm-7">
                        <input type="checkbox" v-model="connection.destination_connection.synchronise_pdf" :true-value="1" :false-value="0" class="form-check-input" id="synchronise_pdf" />
                    </div>
                </div>
                <div class="row" v-if="connection.destination_connection.synchronise_pdf && connection.destination_connection.destination_id == 4">
                    <label for="synchronise_pdf" class="col-sm-5 col-form-label">Synchroniseer <strong>Alleen</strong> de PDF</label>
                    <div class="col-sm-7">
                        <input type="checkbox" v-model="connection.destination_connection.export_pdf_only" :true-value="1" :false-value="0" class="form-check-input" id="export_pdf_only" />
                    </div>
                </div>
                </template>
            </template>
         </template>

    </template>
</template>

<script>
    import destinationService from '@/services/DestinationService';
    import customerService from '@/services/CustomerService';
    import connectionService from '@/services/ConnectionService';
    import sourceService from '@/services/SourceService';
    import store from '@/store/user'

    export default {
        name: 'ConnectionForm',
        props: ['itemData'],
        computed: {
            isAdmin () {
                return store.getters.isAdmin
            },
            source () {
                return this.connection.source_connection ? this.connection.source_connection : {
                        'source_id': 1,
                        'api_key': null,
                        'export_start_date': null,
                    }
            },
            destinationUsesOauth () {
                let desti = this.destinations.filter(destination => destination.id == this.connection.destination_connection.destination_id)

                if (desti && desti[0]) {
                    return desti[0].uses_oauth
                }

                return false
            },

            destinationUsesPayments() {
                let desti = this.destinations.filter(destination => destination.id == this.connection.destination_connection.destination_id)

                if (desti && desti[0]) {
                    return desti[0].uses_payments
                }

                return false
            }
            
        },
        data() {
            return {
                connection: {
                    'source_connection': [{
                        'source_id': null,
                        'api_key': null,
                        'export_start_date': null
                    }],
                    'destination_connection': [{
                        'destination_id': null,
                        'country': null,
                        'use_identifier_as_ref': 1,
                        'journal': null,
                        'default_glaccount': null,
                        'administration': null,
                        'next_debtor_number': null,
                        'environment': null,
                        'debtor_account': null,
                        'crosspost_account': null,
                        'debtor_export_file': null,
                        'whitelist_ip': null,
                        'parent_id': null,
                        'calc_vat': null,
                        'counter_account': null,
                        'synchronise_pdf': 1,
                        'export_pdf_only': 0,
                        'export_target' : 0,
                        'get_payments': 0,
                        'direct_debit_code' : null,
                        'use_costplace' : 0

                        
                    }],
                   
                    'customer_id': null,
                    'billing_start_date': null,
                    'vat_codes': [  
                        {'code': '', 'description': '0%', 'percentage' : 0},
                        {'code': '', 'description': '9%','percentage' : 9},
                        {'code': '', 'description': '21%','percentage' : 21},
                        {'code': '', 'description': 'Verlegd','percentage' : 0},
                        {'code': '', 'description': '6%','percentage' : 6},
                        {'code': '', 'description': '12%','percentage' : 12},
                        {'code': '', 'description': 'intracommunautair','percentage' : 0},
                    ],

                },
                otherConnections: [],
                countries: [
                    {id: 'NL', name: 'Nederland'},
                    {id: 'BE', name: 'België'}
                ],
                descriptionOptions: [
                    {id: 0, name: 'Nee, niet synchroniseren'},
                    {id: 1, name: 'Ja, in veld Omschrijving'},
                    {id: 2, name: 'Ja, in veld Uw ref'}
                ],
                descriptionOptionsAFAS: [
                    {id: 0, name: 'Ja, synchroniseer omschrijving'},
                    {id: 1, name: 'Ja, synchroniseer omschrijving - factuurdatum uit brongegevens'},
                    {id: 2, name: 'Nee, Gebruik factuurnummer - debiteurnaam uit brongegevens'}
                ],
                descriptionOptionsSnelstart: [
                    {id: 0, name: 'Ja, synchroniseer omschrijving'},
                    {id: 1, name: 'Nee, gebruik factuurnummer uit brongegevens'},
                    {id: 2, name: 'Nee, Gebruik factuurnummer - debiteurnaam uit brongegevens'}
                ],
                afasdestinations: [
                    {id: 'afasonlineconnector.nl', name: 'afasonlineconnector.nl'},
                    {id: 'soap.afas.online', name: 'soap.afas.online'},
                    {id: 'soaptest.afas.online', name: 'soaptest.afas.online'}
                ],
                exportAfasTargetOptions: [
                    {id: 0, name: 'Factuur - FinEntries'},
                    {id: 1, name: 'Pakbon - FbDeliveryNote'}
                ],
                 exportExactTargetOptions: [
                    {id: 0, name: 'Memoriaal - Saleslines'},
                    {id: 1, name: 'Factuur - InvoiceLines'},
                    
                ],
                customers: [
                    {id: null, name: ''}
                ],
                sources: [],
                destinations: [],
                submitted: false,
                loading: false,
                invalid: {},
                source_invoices: []
            }
        },
        watch: {
            itemData: function (val) {
                this.connection = val    
            },
        },
        methods: {
            validate(){
                this.invalid = {};
                let valid = true;

                let required = document.querySelectorAll('[required]');

                // for(let field of required) {
                //     let fieldname = field.id;
                    
                //     if(!this.connection[fieldname]) {
                //         this.invalid[fieldname] = true;
                //         valid = false;
                //     }
                // }

                // console.log("required", required);
                return valid;
            },
            getData() {
                return this.connection;
            },
            setData(data) {
                this.connection = data
                console.log(this.connection);
                this.getOtherConnections();
                this.getInvoices();
                
            },
            processFile(event) {
                var file = event.target.files[0];
                this.connection.destination_connection.file_info = {
                    'name': file.name.replace(/[^a-z0-9]/gi, '_'),
                    'type': file.type.split('/')[1],
                    'blob': false,
                };
                
                var reader  = new FileReader();
                reader.readAsDataURL(event.target.files[0])
                reader.onload = (e) => {
                    const imgBase64Path = e.target.result;
                    this.connection.destination_connection.file_info.blob = imgBase64Path
                }

                console.log('file processed')
            },
            reset() {
                this.connection = {
                    'source_connection': {
                        'source_id': 1,
                        'api_key': null,
                    },
                    'destination_connection': {
                        'destination_id': null,
                        'country': null,
                        'journal': null,
                        'default_glaccount': null,
                        'deferred_revenue': null,
                        'automatic_transfers': null,
                        'stop_auto_exports_on_fail': null,
                        'administration': null,
                        'next_debtor_number':null,
                        'environment':null,
                        'crosspost_account': null,
                        'calc_vat': null,
                        'counter_account': null,
                        'direct_debit_code' : null
                    },
                    'customer_id': null,
                    'source_invoices': [],
                    'billing_start_date':null
                },
                this.loading = false
                this.invalid = {}
            },
            oauth() {
                destinationService.getAuthUrl(this.connection.destination_connection.id).then(response => {
                    location.assign(response.data.url)
                }).catch(error => {
                    console.log('error', error)
                    this.toastError(error)
                })
            },
             getSources() {
                sourceService.index().then(response => {
                    this.sources = response.data
                }).catch(error => {
                    console.log('error', error)
                })    
            },
            getDestinations() {
                destinationService.index().then(response => {
                    this.destinations = response.data
                }).catch(error => {
                    console.log('error', error)
                })    
            },
            getCustomers() {
                customerService.index().then(response => {
                    this.customers = response.data
                }).catch(error => {
                    console.log('error', error)
                })    
            },
            getOtherConnections() {
                connectionService.list(this.connection.customer_id).then(response => {
                    this.otherConnections = response.data
                }).catch(error => {
                    console.log('error', error)
                })    
            },
            getInvoices() {
                var bodyData = {}
                // TODO change to check on SOURCE CODE
                if (this.connection.source_connection.api_key) {
                    bodyData = {'api_key': this.connection.source_connection.api_key}
                }
                if (this.connection.source_connection.password) {
                    bodyData = {'password': this.connection.source_connection.password}
                }
                sourceService.getInvoices(this.connection.id, bodyData).then(response => {
                    this.source_invoices = response.data
                }).catch(error => {
                    console.log('error', error)
                })
            },
            setExportStartDate(event) {
                if(this.source_invoices){
                    const searchDate = this.source_invoices.find(element => element.id == event.target.value)
                    this.connection.source_connection.export_start_date = searchDate.date_sent
                }
            },
            filterVatCodes(country, vatCode) {
                return (country == 'BE' && ['0%','21%','Verlegd','6%','12%','intracommunautair'].includes(vatCode)) || (country != 'BE' && ['0%','9%','21%','Verlegd'].includes(vatCode))
            },

            setEndpoint(){    
                console.log(this.connection.source_connection.source_id );
                if(this.connection.source_connection.source_id==1){
                    this.connection.destination_connection.export_target = 1;
                }
                if(this.connection.source_connection.source_id==2){
                    this.connection.destination_connection.export_target = 0;
                }
            }

        },
        mounted() {
            this.getDestinations();
            this.getSources();
            this.getCustomers();
            
        }
    }
</script>
